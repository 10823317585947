import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Rating,
  Link,
  Typography,
  Button,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import PeopleIcon from "@mui/icons-material/People";
import LaptopIcon from "@mui/icons-material/Laptop";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Image from "./../../assets/images/bussiness-female.jpg";
import About from "./../../assets/imgs/About.jpg";
import PaulImage from "./../../assets/images/Img_Gallery/Paul.png";

import Banner from "../../constants/Banner/Banner";
import EqualImageContent from "../../constants/EqualImageContent/EqualImageContent";
import Heading from "../../constants/Heading/Heading";

import "./Home.scss";
import ClientReview from "../../constants/ClientReview/ClientReview";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import HelpYou from "../../constants/HelpYou/HelpYou";

import AutoplayClient from "../../constants/ImageCarousel/AutoplayClient";

// Partner Images
import PartnerImg1 from "./../../assets/images/partners/transInfra.png";
import PartnerImg2 from "./../../assets/images/partners/markInfotech.png";
import PartnerImg3 from "./../../assets/images/partners/sharif.png";
import PartnerImg4 from "./../../assets/images/partners/isometric.jpg";
import PartnerImg5 from "./../../assets/images/partners/kms.jpg";
import PartnerImg6 from "./../../assets/images/partners/markInfotech.png";
import PartnerImg7 from "./../../assets/images/partners/SAF.jpg";
import PartnerImg8 from "./../../assets/images/partners/STC.jpg";
import PartnerImg9 from "./../../assets/images/partners/SRIN.jpg";
import PartnerImg10 from "./../../assets/images/partners/eBMS.jpg";

const gridData = [
  {
    logo: <PeopleIcon />,
    url: "/hrconsulting",
    heading: "HR Consulting",
    description:
      "To provide the finest outcomes for our clients, our team of seasoned HR specialists is extensively trained and outfitted with cutting-edge technologies and methodologies.",
  },
  {
    logo: <LaptopIcon />,
    url: "/technologyconsulting",
    heading: "Technology Consulting",
    description:
      "Insights, tactics, and solutions from a technology consulting firm are invaluable for helping firms make the most of technology in their day-to-day operations.",
  },
  {
    logo: <MonetizationOnIcon />,
    url: "/salesandservices",
    heading: "Sales & Marketing",
    description:
      "Businesses could improve their operations, boost efficiency, and accomplish their objectives with the assistance of consulting firms, which provide a wide range of services and knowledge.",
  },
];

const clientReviewData = {
  backgroundImage: require("./../../assets/images/Client_Review.jpg"), // Import the image from your local directory
  quoteIcon: <FormatQuoteIcon />,
  review:
    "Thank you very much for having guided, implemented, and maintained good HR practices at Your Consulting  for the past 4 years. The process has helped me in managing this important division of the business and develop a good work culture which is transparent we are happy to be with them.",
  clientName: "MOHAMED KALEEMULLAH,",
  clientDescription:
    "Founder, Trans Group of Companies, Trans Infra & Logistics Pvt Ltd",
  starRating: 5,
};

const AboutContent = {
  Image: About,
  Title: "About",
  TitleHeading:
    "We help small business owners and professionals to get things done",
  Paragraph1:
    "Your Consulting is a Man power, Technology Consulting and Sales & Marketing company based in chennai,india. Our aim is to deliver quality job in all the fields, where quality is never an accident it is always the result of intelligent effort and perhaps it is this obsession of job quality, along with attention to detail that brings customers back to us time and again.",
  Paragraph2:
    "We work with enthusiasm to deliver the best to our clients for both established and start-up businesses of all types, industries and sizes. Our work atmosphere is comfortable and relaxed, to deliver quality work to our clients and in providing service beyond their expectations. our clean design and ethical principles, strong focus on usability, technical experience and fine project management skills that together as one to achieve perfect results every time.",
};

const HelpYouContent = {
  Image: PaulImage,
  URL: "/contactUs",
  Heading: "What can we help you overcome?",
  Content: "We help compaines stay ahead in a rapidly changing world.",
  ButtonText: "Talk to US",
};

const partnerCarouselItems = [
  PartnerImg1,
  PartnerImg2,
  PartnerImg3,
  PartnerImg4,
  PartnerImg5,
  PartnerImg6,
  PartnerImg7,
  PartnerImg8,
  PartnerImg9,
  PartnerImg10,
];

const Home = () => {
  return (
    <>
      <Banner
        Image={Image}
        BannerHeading={"Strong Business Strategy to grow your business"}
        IsHomeBanner={true}
      ></Banner>

      <Box className={"sections"}>
        <EqualImageContent
          Image={AboutContent.Image}
          Title={AboutContent.Title}
          TitleHeading={AboutContent.TitleHeading}
          Paragraph1={AboutContent.Paragraph1}
          Paragraph2={AboutContent.Paragraph2}
          IsBackgroundColored={true}
        />
      </Box>

      <Box py={14} className={"sections"}>
        <Container>
          <Heading Title={"WHAT WE DO"} TitleHeading={"Consulting Services"} />
          <Box p={3}></Box>
          <Grid container spacing={2}>
            {gridData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={1}
                  style={{
                    padding: "20px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={"iconWeDo"}>{item.logo}</div>
                  <Typography
                    variant="h6"
                    fontWeight={"400"}
                    style={{ marginTop: "0px" }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    {item.description}
                  </Typography>
                  <div style={{ marginTop: "auto", paddingTop: "20px" }}>
                    <Link
                      variant="body2"
                      href={item.url}
                      color="primary"
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read More
                    </Link>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={"sections"} p={8}>
        <Container>
          <Heading
            Title={""}
            TitleHeading={"Our Happy Clients"}
            IsTextCenter={true}
          />
          <Box p={3}></Box>
          <AutoplayClient carouselItems={partnerCarouselItems} />
        </Container>
      </Box>

      <Box className={"sections"}>
        <ClientReview clientReviewData={clientReviewData} />
      </Box>

      <Box className={"section canWeHelp"}>
        <Container>
          <HelpYou
            Image={HelpYouContent.Image}
            Heading={HelpYouContent.Heading}
            Content={HelpYouContent.Content}
            ButtonText={HelpYouContent.ButtonText}
            path={"/contactUs"}
          />
        </Container>
      </Box>
    </>
  );
};

export default Home;
