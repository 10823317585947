import Mission from "./../../assets/images/mission.jpg";
import Vision from "./../../assets/images/OurVision.jpg";
import Values from "./../../assets/images/values.jpg";

export const AboutUs = [
  {
    ourMission: [
      {
        Image: Mission,
        Heading: "Our Mission",
        Content:
          "Your Consulting aspire is to become a progressive Man Power consulting,SoftwareDevelopment and Webdesign Company that offers brilliant and cutting edge technological solutions to a diverse range of clients with the best integration of professionalism and ingenuity.",
      },
    ],

    ourVision: [
      {
        Image: Vision,
        Heading: "Our Vision",
        Content:
          "Your Consulting envision an environment whereby we are capable of effectively utilizing people, resources and technology so that this combination effectually assists our clients in reaching the pinnacle of success in their business.",
      },
    ],

    ourValues: [
      {
        Image: Values,
        Heading: "Our Values",
        Content:
          "Your Consulting value is to understand the needs of our clients and provide them with quality, result oriented and cost-effective solutions.",
      },
    ],
  },
];
