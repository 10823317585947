import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import YCLogo from "./../images/YC_whatsapp_Logo.jpg";

const WhatsAppWidgetIcon = () => {
  return (
    <WhatsAppWidget
      phoneNo="919840625777"
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={5000}
      messageBox={true}
      messageBoxTxt=""
      iconSize="40"
      iconColor="white"
      iconBgColor="green"
      headerIcon={YCLogo}
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="skyblue"
      headerTitle="Support Assistant"
      headerCaption="Online"
      bodyBgColor="#f3f3"
      chatPersonName="Support"
      chatMessage={
        <>
          Hi there 👋 <br />
          <br /> How can I help you?
        </>
      }
      footerBgColor="#999"
      placeholder="Type a message.."
      btnBgColor="yellow"
      btnTxt="Start Chat"
      btnTxtColor="black"
    />
  );
};

export default WhatsAppWidgetIcon;
