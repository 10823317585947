import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Button,
  Rating,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemText
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Banner from "../../constants/Banner/Banner";
import SalesImg from "../../assets/imgs/Sales.jpg";
import PaulImage from "./../../assets/images/Img_Gallery/Paul.png";

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ClientReview from "../../constants/ClientReview/ClientReview";
import Heading from "../../constants/Heading/Heading";
import HelpYou from "../../constants/HelpYou/HelpYou";

import SchoolIcon from '@mui/icons-material/School';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import TimelineIcon from '@mui/icons-material/Timeline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';

const items = [
  {
    heading: "Planning strategically",
    body: "We engage closely with our clients to create long-term plans that support their corporate objectives. We offer market research, competitive analysis, and opportunity identification as part of our strategic planning services.",
    icon: <AdsClickOutlinedIcon />
  },
  {
    heading: "Process augmentation",
    body: "We assist our clients in streamlining their operations and procedures to boost productivity and cut expenses. Process mapping, identifying bottlenecks, and putting best practices into practice are all part of our process improvement services.",
    icon: <SchoolIcon />
  },
  {
    heading: "Improvement of the organization",
    body: "We support our clients in creating an effective and long-lasting organizational culture. We offer change management, workforce engagement, and leadership development.",
    icon: <CorporateFareOutlinedIcon />
  },
  {
    heading: "Sales and Marketing",
    body: "To reach their target audience and increase revenue, we assist firms in developing powerful marketing and sales strategies. Market research, brand positioning, and sales training are some of the services we offer.",
    icon: <TrendingUpOutlinedIcon />
  }
];

const whyChooseUs = [
  {
    step: '01',
    heading: "Industry knowledge",
    body: "Our team of consultants has a wide range of educational backgrounds and in-depth industry experience. This helps us to comprehend the particular difficulties and possibilities faced by each sector and to offer specialized solutions.",
    icon: <SchoolIcon />
  },
  {
    step: '02',
    heading: "Individualized solutions",
    body: "We reject the idea of a one-size-fits-all strategy. We collaborate closely with our clients to comprehend their unique demands and provide solutions that are workable, efficient, and long-lasting.",
    icon: <EmojiObjectsIcon />
  },
  {
    step: '03',
    heading: "Proven track record",
    body: "We have a track record of providing our clients with results. Businesses have benefited from our services in terms of operational enhancements, revenue growth, and goal achievement.",
    icon: <SpatialTrackingIcon />
  },
  {
    step: '04',
    heading: "Collaborative approach",
    body: "We consider our clients to be partners with whom we should work closely. Our teamwork approach makes sure that our clients are informed at all times and are participating in the decision-making process.",
    icon: <TimelineIcon />
  },
  {
    step: '05',
    heading: "Affordable",
    body: "We are aware that expense is an important consideration for organizations, particularly small and medium-sized ones. To guarantee that our clients receive the most value for their money, we thus provide our services at reasonable costs.",
    icon: <CurrencyExchangeIcon />
  }
];

const clientReviewData = {
  backgroundImage: require("./../../assets/images/Client_Review.jpg"), // Import the image from your local directory
  quoteIcon: <FormatQuoteIcon />,
  review:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio vitae justo iaculis vehicula.",
  clientName: "John Doe",
  clientDescription: 'Founder, Trans Group of Companies',
  starRating: 4,
};

const HelpYouContent = {
  Image: PaulImage,
  Heading: 'What can we help you overcome?',
  Content: 'We help compaines stay ahead in a rapidly changing world.',
  ButtonText: 'Talk to US'
}


const FAQSection = [
  {
    question: "Who are those who work alongside you?",
    answer:
      "We deal with companies of all sizes, ranging from small startups to huge multinationals, in a number of different industries.",
  },
  {
    question: "How long does a typical consulting project last?",
    answer:
      "The length of our consulting engagement is determined by the nature of the project and the particular requirements of our customers. Setting realistic deadlines and milestones is a joint effort between us and our clients.",
  },
  {
    question: "Do you promise outcomes?",
    answer:
      "Although we cannot promise particular outcomes, our track record of providing our clients with positive results speaks for itself.",
  },
  {
    question: "How do you maintain privacy?",
    answer:
      "We take the security of your privacy extremely seriously. All customer information is kept secret and secure by our stringent standards and processes."
  },

];


const SalesandMarketing = () => {
  return (
    <>
      <Banner
        Image={SalesImg}
        BannerTitle={"Consulting & Service"}
        BannerHeading={"Sales & Marketing"}
        BannerDescription={"Maximizing Your Potential, Minimizing Your Risks"}
        IsHomeBanner={false}
      ></Banner>

      <Box className={'sections'} py={10}>
        <Container>
          <Typography variant="subtitle1" textAlign={'center'}>
            Organizations encounter a variety of difficulties in the cutthroat commercial
            environment of today. They have to adjust continually to shifting market
            conditions, altering consumer preferences, and new technological developments.
            As a result, businesses frequently go to consulting companies for advice and
            assistance in overcoming these difficult obstacles. Businesses could improve their
            operations, boost efficiency, and accomplish their objectives with the assistance of
            consulting firms, which provide a wide range of services and knowledge.
          </Typography>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Our Offering Consists of"} IsTextCenter={true} />
          <Box maxWidth="md" margin={'auto'}>
            <Typography variant="body1" textAlign={'center'} marginBottom={4}>
              To meet the various demands of our clients, we at Your Consulting provide a wide
              range of services. Our team of knowledgeable consultants has a lot of experience in
              a variety of sectors, which enables us to offer specialized solutions that cater to the
              unique requirements of our clients. Our offerings consist of:
            </Typography>
          </Box>
          <Box p={2}></Box>
          {/* <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={1} sx={{ p: 2 }} style={{ height: '100%' }}>
                  <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid> */}

          <Grid container spacing={4}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Box key={index} className={'infographics2'}>
                  <Box className={"steps-" + `${index + 1}`}>
                    <span>step
                      <span>0{index + 1}</span>
                    </span>
                  </Box>
                  <Box className={'contentIcons'}>
                    <Box>
                      <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                    </Box>
                    <Box paddingLeft={2}>
                      {item.icon}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Why ought you to pick us for sales and services?"} IsTextCenter={true} />
          <Box p={2}></Box>
          <Box className="infographyListItems1" maxWidth={'720px'} margin={'auto'}>
            {whyChooseUs.map((item, index) => (
              // <ListItem key={index}>
              //   <ListItemText
              //     primary={
              //       <React.Fragment>
              //         <Typography
              //           component="span"
              //           variant="body2"
              //           color="text.primary"
              //         >
              //           {item.step}
              //         </Typography>
              //         {item.heading}
              //       </React.Fragment>
              //     }
              //     secondary={item.body}
              //   />
              // </ListItem>

              <Box className={"list-" + `${index}`}>
                <Typography variant="body2" className="steps">
                  Step<span>{item.step}</span>
                </Typography>
                <Box className={'infographyContent'}>
                  <Typography variant="h6">
                    {item.heading}
                  </Typography>
                  <Typography component={'div'} variant="body1">
                    {item.body}
                  </Typography>
                </Box>
                <Box className={'icon'}>
                  {item.icon}
                </Box>
              </Box>

            ))}
          </Box>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container maxWidth="md">
          <Heading Title={''} TitleHeading={"FAQs"} IsTextCenter={true} />
          <Box p={2}></Box>
          {FAQSection.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="subtitle2">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Typography variant="body1" textAlign={'center'} marginTop={4}>
            In conclusion, selecting the best consulting firm is essential for every organization
            to succeed. At Your Consulting, we provide a variety of services to assist
            organizations in overcoming obstacles, enhancing their processes, and achieving
            their objectives. We take great satisfaction in our industry knowledge, specialized
            solutions, successful track record, teamwork style, and competitive pricing. We are
            dedicated to collaborating closely with our clients as partners and work hard to
            produce concrete outcomes that foster long-term success and growth. To learn how
            we can help your company grow, get in touch with us right away
          </Typography>
        </Container>
      </Box>

      <Box className={'sections'}>
        <ClientReview clientReviewData={clientReviewData} />
      </Box>

      <Box className={'section canWeHelp'}>
        <Container>
          <HelpYou
            Image={HelpYouContent.Image}
            Heading={HelpYouContent.Heading}
            Content={HelpYouContent.Content}
            ButtonText={HelpYouContent.ButtonText}
            path={"/contactUs"}
          />
        </Container>
      </Box>
    </>
  );
};

export default SalesandMarketing;
