import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./App.scss";
import OurTeam from "./containers/AboutUs/OurTeam";
import Home from "./containers/Home/Home";
import AppHeader from "./layouts/AppHeader/AppHeader";
import AppFooter from "./layouts/AppFooter/AppFooter";
import HRConsulting from "./containers/ConsultingServices/HR";
import TechnologyConsulting from "./containers/ConsultingServices/TechnologyConsulting";
import SlaesandServices from "./containers/ConsultingServices/SalesandServices";
import NotFound404 from "./containers/404/NotFound404";
import SucessStories from "./containers/SucessStories/Sucess";
import ContactUs from "./containers/ContactUs/ContactUs";
import { Toolbar } from "@mui/material";

function App() {
  return (
    <>
      <AppHeader />
      <Toolbar />
      <div className="mainContent">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="aboutUs" element={<OurTeam />} />
          <Route path="hrconsulting" element={<HRConsulting />} />
          <Route
            path="technologyconsulting"
            element={<TechnologyConsulting />}
          />
          <Route path="salesandservices" element={<SlaesandServices />} />
          <Route path="sucessstories" element={<SucessStories />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </div>
      <AppFooter />
    </>
  );
}

export default App;
