import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Rating,
  Button,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemText

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import Banner from "../../constants/Banner/Banner";
import TechnologyImg from "../../assets/imgs/technology.jpg";
import PaulImage from "./../../assets/images/Img_Gallery/Paul.png";

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ClientReview from "../../constants/ClientReview/ClientReview";
import Heading from "../../constants/Heading/Heading";
import HelpYou from "../../constants/HelpYou/HelpYou";


import WbCloudyOutlinedIcon from '@mui/icons-material/WbCloudyOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';

const items = [
  {
    heading: "Technology Strategy and Planning",
    body: "We work alongside our customers to comprehend their company goals and objectives and create a roadmap for technology that will help them accomplish those goals. To find areas for improvement and create a long-term technology plan, our team thoroughly evaluates a company's present technology systems, procedures, and infrastructure.",
    icon: <PhonelinkRingOutlinedIcon />
  },
  {
    heading: "Digital Transformation",
    body: "Our team assists companies in using cutting-edge technology to enhance customer experience and performance. We assist our clients in maintaining a competitive edge in the rapidly evolving digital environment by optimizing operations, applying automation, and integrating systems",
    icon: <MemoryOutlinedIcon />
  },
  {
    heading: "Software Selection and Implementation",
    body: "We help our clients choose and implement practice the finest software options for their particular set of business requirements. Our technology professionals help organizations through every step of the process, from vendor selection to project management and training, for everything from CRM systems and ERP solutions to bespoke applications.",
    icon: <WebhookOutlinedIcon />
  },
  {
    heading: "Cybersceurity",
    body: "As a result of the proliferation of online risks, firms must have a solid cybersecurity plan in place. We provide cybersecurity consulting services to assist firms in risk assessment, risk mitigation planning, and security product implementation.",
    icon: <GppGoodOutlinedIcon />
  },
  {
    heading: "IT Infrastructure and Cloud Computing",
    body: "We provide organizations with guidance and help for the planning, development, and administration of their IT infrastructure. To improve productivity and save costs, our team also assists with the implementation of cloud computing solutions.",
    icon: <WbCloudyOutlinedIcon />
  }
];

const whyChooseUs = [
  {
    step: 1,
    heading: "Personalized Solutions",
    body: "We are aware that every organization has a different set of problems and objectives. We make sure to offer the finest solutions for each client's business by customizing our services to meet their unique demands."
  },
  {
    step: 2,
    heading: "Skilled Experts",
    body: "Our group of professionals has a plethora of knowledge and perience in many different fields, including technology and business. This makes it possible for us to offer our clients insightful advice that is both pertinent to and useful for their organization."
  },
  {
    step: 3,
    heading: "Economical",
    body: "Consulting in technology can turn out to be expensive, particularly for small firms. Your Consulting provides affordable prices and works closely with customers to make sure our services are within their spending limits."
  },
  {
    step: 4,
    heading: "Proven Track Record:",
    body: "We have a track record of providing our clients with excellent technological solutions. The quality of our services and the contentment of our customers are attested to by our client testimonials and recommendations."
  },
  {
    step: 5,
    heading: "Customer service dedication",
    body: "At Your Consulting, we place a high value on client happiness. In order to meet and surpass our client's requirements and expectations, we work closely with them throughout the whole process."
  }
];

const clientReviewData = {
  backgroundImage: require("./../../assets/images/Client_Review.jpg"), // Import the image from your local directory
  quoteIcon: "❝",
  review:
    "They always understand our position and work with us to find good tailored made customized solutions together which will make our us satisfied in all terms.",
  clientName: "Yunus Kazi",
  clientDescription: 'Managing Director / Founder, eBMS Solutions Pvt Ltd',
  starRating: 4,
};

const HelpYouContent = {
  Image: PaulImage,
  Heading: 'What can we help you overcome?',
  Content: 'We help compaines stay ahead in a rapidly changing world.',
  ButtonText: 'Talk to US'
}

const FAQSection = [
  {
    question: "Is consulting in technology only available to large business entities?",
    answer:
      "No, organizations of all sizes can profit from using technology consulting services. Your consulting assists small, medium-sized, and large enterprises in achieving their technological objectives and enhancing overall performance.",
  },
  {
    question: "How long does it take for technology consulting services to provide results?",
    answer:
      "Depending on the particular project and the requirements of the client, the time frame for seeing outcomes will change. Our staff works hard to provide fast answers, and our clients have already noticed improvements within just a few weeks.",
  },
  {
    question: "Can technology consulting make my company more cost-effective?",
    answer:
      "Yes, there are a number of ways that technology consulting could help firms save money. Our clients have experienced considerable cost savings and increased productivity as a result of reducing operations, integrating automation, and deploying cost-effective solutions.",
  },
  {
    question: "Will technology consulting interfere with how I run my business?",
    answer:
      "We are aware that companies cannot afford to have severe business interruptions. To reduce any delays and enable a seamless transition to new technological solutions, our team collaborates closely with customers.",
  },
  {
    question: "What happens if my company already has an in-house IT team?",
    answer:
      "Yes, there are a number of ways that technology consulting could help firms save money. Our clients have experienced considerable cost savings and increased productivity as a result of reducing operations, integrating automation, and deploying cost-effective solutions.",
  },
];

const TechnologyConsulting = () => {
  return (
    <>
      <Banner
        Image={TechnologyImg}
        BannerTitle={"Consulting & Service"}
        BannerHeading={"Technology Consulting"}
        BannerDescription={"Elevating businesses through bespoke technology consulting."}
        IsHomeBanner={false}
      ></Banner>

      <Box className={'sections'} py={10}>
        <Container>
          <Typography variant="subtitle1" textAlign={'center'}>
            Technology has become a crucial component of business, enabling businesses to expand their operations, tap into new markets, and boost their profits. However, companies could find it difficult to keep up with the rapidly changing world of technology. Your consulting can help with that. Insights, tactics, and solutions from a technology consulting firm are invaluable for helping firms make the most of technology in their day-to-day operations.
          </Typography>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Our Offering Consists of"} IsTextCenter={true} />
          <Box maxWidth="md" margin={'auto'}>
            <Typography variant="body1" textAlign={'center'} marginBottom={4}>
              We at Your Consulting observe the value of technology in the workplace and provide a wide range of technology consulting services to assist businesses in achieving their objectives. We are a dependable partner for companies of all sizes as our team of professionals has in-depth knowledge of the market and the most recent technical developments.
            </Typography>
          </Box>
          {/* <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={1} sx={{ p: 2 }} style={{ height: '100%' }}>
                  <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid> */}

          <Grid container spacing={4}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Box key={index} className={'infographics2'}>
                  <Box className={"steps-" + `${index + 1}`}>
                    <span>step
                      <span>0{index + 1}</span>
                    </span>
                  </Box>
                  <Box className={'contentIcons'}>
                    <Box>
                      <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                    </Box>
                    <Box paddingLeft={2}>
                      {item.icon}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Why Choose Us for Sales and Services"} IsTextCenter={true} />
          <Box p={2}></Box>
          <List className="infographyListItems">
            {whyChooseUs.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.step}
                      </Typography>
                      {item.heading}
                    </React.Fragment>
                  }
                  secondary={item.body}
                />
              </ListItem>

            ))}
          </List>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container maxWidth="md">
          <Heading Title={''} TitleHeading={"FAQs"} IsTextCenter={true} />
          <Box p={2}></Box>
          {FAQSection.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="subtitle2">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Typography variant="body1" textAlign={'center'} marginTop={4}>
            In conclusion, Your Consulting is committed to assisting companies to leverage technology to accomplish their objectives and outperform their rivals. We are the number one choice for technology consulting services, attributable to our customized solutions, skilled staff, affordable pricing, and dedication to client care.
          </Typography>
        </Container>
      </Box>

      <Box className={'sections'}>
        <ClientReview clientReviewData={clientReviewData} />
      </Box>

      <Box className={'section canWeHelp'}>
        <Container>
          <HelpYou
            Image={HelpYouContent.Image}
            Heading={HelpYouContent.Heading}
            Content={HelpYouContent.Content}
            ButtonText={HelpYouContent.ButtonText}
            path={"/contactUs"}
          />
        </Container>
      </Box>
    </>
  );
};

export default TechnologyConsulting;
