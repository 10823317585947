import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Rating,
  Paper,
  Button,
  Link,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemText

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



import Banner from "../../constants/Banner/Banner";
import HRBanner from "../../assets/imgs/HR_Consulting.jpg";
import PaulImage from "./../../assets/images/Img_Gallery/Paul.png";
import Heading from "../../constants/Heading/Heading";
import ClientReview from "../../constants/ClientReview/ClientReview";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HelpYou from "../../constants/HelpYou/HelpYou";

import './Services.scss'

import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CodeOffOutlinedIcon from '@mui/icons-material/CodeOffOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const items = [
  {
    heading: "Talent Recruiting and Acquisition",
    body: "We know the importance of having the proper personnel in your organization for success. For this reason, we provide specialized talent acquisition and recruiting services to aid in your search for and recruitment of the best individuals in your sector. Our staff finds the ideal people for your company by utilizing cutting-edge search strategies and evaluations.",
    icon: <GroupAddOutlinedIcon />
  },
  {
    heading: "Maintainance of Performance",
    body: "Measurement and enhancement of staff productivity and motivation depend on efficient performance management. Our staff will collaborate with you to create a system for managing performance that supports your company's objectives and gives employees ongoing feedback.",
    icon: <EngineeringOutlinedIcon />
  },
  {
    heading: "Training and Development",
    body: "A crucial element in keeping top talent on board and maintaining an edge over the competition is investing in the development of your staff. According to your organization's unique requirements and goals, we provide a range of training and development programs. Your staff will get the finest learning experience courtesy of our cutting-edge training methodologies and resources.",
    icon: <CodeOffOutlinedIcon />
  },
  {
    heading: "Reimbursement and Benefits",
    body: "We know the significance of just and reasonable pay and benefits for luring and keeping talent. Our team will assist you in creating and implementing a compensation and benefits plan that is optimized for your company's needs and compliant with regulatory requirements.",
    icon: <LoyaltyOutlinedIcon />
  },
  {
    heading: "Risk management and HR compliance",
    body: "For groups, maintaining compliance with HR rules and regulations may be difficult. For your company to continue to operate in compliance with all HR rules and regulations, our experts must stay current on these issues. Additionally, we provide risk management services to locate and reduce significant HR issues.",
    icon: <ManageAccountsOutlinedIcon />
  }
];

const whyChooseUs = [
  {
    step: 1,
    heading: "Wide-ranging Experience",
    body: "Our HR experts at Your Consulting have years of experience and competence in several sectors and HR specializations, giving you a broad range of information and abilities."
  },
  {
    step: 2,
    heading: "Tailored Solutions",
    body: "Recognizing that every organization is different, we provide HR solutions that are specifically designed to meet your needs and achieve your objectives. This guarantees the most outstanding results for your company."
  },
  {
    step: 3,
    heading: "Economical",
    body: "You could receive high-quality HR solutions from our consulting services at a fair price, which will ultimately save you time and money."
  },
  {
    step: 4,
    heading: "Strategic Approach",
    body: "To maximize the influence of our solutions on the performance of your organization, we use a strategic approach to HR consulting by coordinating our services with your corporate strategy."
  },
  {
    step: 5,
    heading: "Human-Centered Focus",
    body: "We take a human-centered approach, placing people at the centre of our solutions and fostering an environment where your staff can work effectively and happily."
  },
  {
    step: 6,
    heading: "Confidentiality",
    body: "We place ethics and confidentiality first in every consulting project to protect your company's and its workers' private data."
  }
];

const clientReviewData = {
  backgroundImage: require("./../../assets/images/Client_Review.jpg"), // Import the image from your local directory
  quoteIcon: <FormatQuoteIcon />,
  review:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio vitae justo iaculis vehicula.",
  clientName: "John Doe",
  clientDescription: 'Founder, Trans Group of Companies',
  starRating: 4,
};

const HelpYouContent = {
  Image: PaulImage,
  Heading: 'What can we help you overcome?',
  Content: 'We help compaines stay ahead in a rapidly changing world.',
  ButtonText: 'Talk to US'
}

const FAQSection = [
  {
    question: "What is HR consulting?",
    answer:
      "HR consulting is a service that offers knowledgeable guidance and assistance to companies on a range of HR-related issues, including hiring, employee relations, performance management, training and development, and HR strategy. Experienced HR specialists who engage with businesses to meet their unique HR issues and difficulties typically offer this service.",
  },
  {
    question: "What advantages could HR consulting services offer?",
    answer:
      "Access to specialized knowledge, cost-effectiveness, time efficiency, and the capacity to strategically manage HR challenges are all advantages of your consulting. Companies could improve their HR procedures, lower employee turnover, maintain regulatory compliance, and promote a healthy workplace culture with the assistance and direction of an expert HR consultant.",
  },
  {
    question: "How can your HR consulting benefit my company?",
    answer:
      "Our HR consultancy could help your company by offering specialized answers to your HR problems and requirements. This might entail creating and executing HR policies and procedures, running training and development programs, and offering advice on dealing with coworkers and adhering to the law. Overall, HR consulting attempts to increase organizational performance and help your company expand and succeed.",
  },
  {
    question: "Is HR consulting only available to big businesses?",
    answer:
      "No, organizations of all sizes can benefit from our HR consulting services. HR consulting could provide invaluable advice and direction to help you manage your employees successfully and efficiently, regardless of how big or small your business is. The services of HR consultants can be customized to fit the demands and financial constraints."
  },

];


const HR_Consulting = () => {
  return (
    <>
      <Banner
        Image={HRBanner}
        BannerTitle={"Consulting & Service"}
        BannerHeading={"HR Consulting "}
        BannerDescription={"Try Your Consulting to see the difference in HR knowledge; your success is our top focus."}
        IsHomeBanner={false}
      ></Banner>

      <Box className={'sections'} py={10}>
        <Container maxWidth="md">
          <Typography variant="subtitle1" textAlign={'center'}>
            At Your Consulting, we provide top-notch HR consulting services to support
            organizations in optimizing their HR policies and procedures. To provide the finest
            outcomes for our clients, our team of seasoned HR specialists is extensively trained
            and outfitted with cutting-edge technologies and methodologies.
          </Typography>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Our Offering Consists of"} IsTextCenter={true} />
          <Box p={2}></Box>
          {/* <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={1} sx={{ p: 2 }} style={{ height: '100%' }}>
                  <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid> */}

          <Grid container spacing={4}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Box key={index} className={'infographics2'}>
                  <Box className={"steps-" + `${index + 1}`}>
                    <span>step
                      <span>0{index + 1}</span>
                    </span>
                  </Box>
                  <Box className={'contentIcons'}>
                    <Box>
                      <Typography variant="h6" fontWeight={'400'}>{item.heading}</Typography>
                      <Typography variant="body1" style={{ marginTop: "10px" }}>{item.body}</Typography>
                    </Box>
                    <Box paddingLeft={2}>
                      {item.icon}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container>
          <Heading Title={''} TitleHeading={"Why Choose Us for Sales and Services"} IsTextCenter={true} />
          <Box p={2}></Box>
          <List className="listItems">
            {whyChooseUs.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.step}
                      </Typography>
                      {item.heading}
                    </React.Fragment>
                  }
                  secondary={item.body}
                />
              </ListItem>

            ))}
          </List>
        </Container>
      </Box>

      <Box className={'sections'} py={10}>
        <Container maxWidth="md">
          <Heading Title={''} TitleHeading={"FAQs"} IsTextCenter={true} />
          <Box p={2}></Box>
          {FAQSection.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="subtitle2">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Typography variant="body1" textAlign={'center'} marginTop={4}>
            Join together with Your Consulting for the HR consulting services available. To
            find out how we could support the expansion and success of your company, get in
            touch with us right now.
          </Typography>
        </Container>
      </Box>



      <Box className={'sections'}>
        <ClientReview clientReviewData={clientReviewData} />
      </Box>

      <Box className={'section canWeHelp'}>
        <Container>
          <HelpYou
            Image={HelpYouContent.Image}
            Heading={HelpYouContent.Heading}
            Content={HelpYouContent.Content}
            ButtonText={HelpYouContent.ButtonText}
            path={"/contactUs"}
          />
        </Container>
      </Box>


    </>
  );
};

export default HR_Consulting;
