import React from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Paper,
  Rating,
  Link,
  Button,
} from "@mui/material";
import { Star } from "@mui/icons-material";

import Banner from "../../constants/Banner/Banner";
import SucessImg from "./../../assets/images/scuess.jpg";
import Image1 from "./../../assets/images/Img_Gallery/Image1.jpg";
import Image2 from "./../../assets/images/Img_Gallery/Image2.jpg";
import Image3 from "./../../assets/images/Img_Gallery/Image3.jpg";
import PaulImage from "./../../assets/images/Img_Gallery/Paul.png";
import Heading from "../../constants/Heading/Heading";
import HelpYou from "../../constants/HelpYou/HelpYou";
const items = [
  {
    image: Image1,
    title: "Item 1",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim.",
  },
  {
    image: Image2,
    title: "Item 2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim.",
  },
  {
    image: Image3,
    title: "Item 3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim.",
  },
];

const clientReviews = [
  {
    id: 1,
    rating: 5,
    text: "Thank you very much for having guided, implemented, and maintained good HR practices at Your Consulting  for the past 4 years. The process has helped me in managing this important division of the business and develop a good work culture which is transparent we are happy to be with them.",
    clientName: "MOHAMED KALEEMULLAH",
    clientDescription: 'Founder, Trans Group of Companies, Trans Infra & Logistics Pvt Ltd',
  },
  {
    id: 2,
    rating: 5,
    text: "They always understand our position and work with us to find good tailored made customized solutions together which will make our us satisfied in all terms.",
    clientName: "Yunus Kazi",
    clientDescription: 'Managing Director / Founder, eBMS Solutions Pvt Ltd',
  }
];

const HelpYouContent = {
  Image: PaulImage,
  Heading: 'What can we help you overcome?',
  Content: 'We help compaines stay ahead in a rapidly changing world.',
  ButtonText: 'Talk to US'
}

const Sucess = () => {
  return (
    <>
      <Banner
        Image={SucessImg}
        BannerTitle={"Success Stories"}
        BannerHeading={"Client Results"}
        BannerDescription={""}
        IsHomeBanner={false}
      ></Banner>
      {/* <Box py={12} className={'sections'}>
        <Container>
          <Heading Title={'REAL LIFE CASES'} TitleHeading={'Case Studies'} IsTextCenter={true} />
          <Box p={3}></Box>
          <Grid container spacing={3}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image={item.image}
                    alt={item.title}
                  />
                  <CardContent>
                    <Typography variant="h6" fontWeight={'400'} style={{ marginBottom: "10px" }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "20px" }}>
                      {item.content}
                    </Typography>
                    <Link
                      variant="body2"
                      href="#"
                      color="primary"
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
      <Box py={12} className={'sections'}>
        <Container>
          <Heading Title={''} TitleHeading={'What Our Clients Say'} IsTextCenter={true} />
          <Box p={3}></Box>

          <Grid container spacing={2}>
            {clientReviews.map((review) => (
              <Grid item key={review.id} xs={12} md={6}>
                {" "}
                {/* Updated the sizes to xs=12, sm=6, and md=6 */}
                <Paper elevation={1} style={{ padding: "24px", height: '100%' }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Rating
                      name="read-only"
                      value={review.rating}
                      precision={0.5}
                      size="large"
                      readOnly
                      emptyIcon={<Star fontSize="inherit" />}
                    />
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ margin: "16px 0" }}
                    >
                      {review.text}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      - {review.clientName}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                    - {review.clientDescription}
                </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box className={'section canWeHelp'}>
        <Container>
          <HelpYou
            Image={HelpYouContent.Image}
            Heading={HelpYouContent.Heading}
            Content={HelpYouContent.Content}
            ButtonText={HelpYouContent.ButtonText}
            path={"/contactUs"}
          />
        </Container>
      </Box>

    </>
  );
};

export default Sucess;
