import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import "./style.scss";
import Heading from '../Heading/Heading';

const EqualImageContent = (props) => {
    console.log(props.IsBackgroundColored, "props")
    const { Image, Title, TitleHeading, Paragraph1, Paragraph2, IsBackgroundColored } = props
    return (
        <Box>
            <Grid container>
                {/* First Row */}
                <Grid item xs={12} lg={6} style={{ backgroundImage: `URL(${Image})`, backgroundPosition: 'center', backgroundSize: 'cover', minHeight: '300px' }} className='imageOverlay'></Grid>
                <Grid item xs={12} lg={6}>
                    <Box style={{ padding: '15%' }}>
                        <Heading Title={Title} TitleHeading={TitleHeading} />
                        <Typography variant="body1" marginBottom={2.5}>
                            {Paragraph1}
                        </Typography>
                        <Typography variant="body1">
                            {Paragraph2}
                        </Typography>
                        <Box marginTop={5}>
                            <Button variant="contained" color={IsBackgroundColored ? "primary" : "primary" } style={{ width: "fit-content" }}>
                                Read more
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EqualImageContent