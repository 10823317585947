import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.scss'

const AutoplayClient = (props) => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        className: "center",
        centerMode: true,
    };
    return (
        <Slider {...settings} className='partnerSlider'>
            {props.carouselItems.map((val, i) => (
                <img src={val} alt={i} style={{ height: '100px', width: 'auto' }} />
            ))}

        </Slider>
    );
}

export default AutoplayClient