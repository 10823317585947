import * as React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Avatar,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import NewLogo from "./../../assets/imgs/NewLogo.png";
import { NavItems } from "../../assets/json/NavItems";
import "./style.scss";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const drawerWidth = 240;

export default function AppHeader(props) {
  let location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const menuList = (
    <List className="navMenu">
      {NavItems.map((nav, key) =>
        Object.keys(nav).some((val) => Array.isArray(nav[val])) ? (
          <ListItem key={key}>
            <ListItemButton
              className={
                nav.children.map((val) => val.path) === location.pathname
                  ? "active"
                  : "noactive"
              }
              component={Link}
            >
              {nav.pageName}
              <ExpandMoreIcon />
            </ListItemButton>
            <List className="subNavMenu">
              {nav.children.map((nav, key) => (
                <>
                  <ListItem key={key}>
                    <ListItemButton
                      component={Link}
                      to={nav.path}
                      className={
                        nav.path === location.pathname ? "active" : "noactive"
                      }
                      target={nav.download ? "_blank" : ""}
                    >
                      {nav.pageName}
                    </ListItemButton>
                  </ListItem>
                </>
              ))}
            </List>
          </ListItem>
        ) : (
          <ListItem>
            <ListItemButton
              key={key}
              component={Link}
              to={nav.path}
              className={nav.path === location.pathname ? "active" : "noactive"}
              target={nav.download ? "_blank" : ""}
            >
              {nav.pageName}
            </ListItemButton>
          </ListItem>
        )
      )}
    </List>
  );

  const drawer = (
    <Box 
    // onClick={handleDrawerToggle} 
    sx={{ textAlign: "center" }}>
      <Avatar
        component={Link}
        to={"/"}
        alt="NewLogo"
        src={NewLogo}
        sx={{ height: 56, width: "auto", borderRadius: 0 }}
      />
      <Divider />
      {menuList}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar component="nav" color="default">
          <Toolbar>
            <Avatar
              component={Link}
              to={"/"}
              alt="NewLogo"
              src={NewLogo}
              sx={{ height: 56, width: "auto", borderRadius: 0 }}
            />

            <Box
              sx={{ display: { xs: "none", md: "block" }, marginLeft: "auto" }}
            >
              {menuList}
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, ml: "auto", display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
