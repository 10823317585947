import { Box, Rating, Typography, Paper, Container } from '@mui/material'
import React from 'react'
import "./ClientReview.scss"

const ClientReview = (props) => {
    const { clientReviewData } = props
    return (
        <Box
            component={Paper}
            className={'clientReviewSection'}
            sx={{
                backgroundImage: `url(${clientReviewData.backgroundImage})`
            }}
        >
            <Container maxWidth="md">
                <Box className={'quoteIcon'}>
                    {clientReviewData.quoteIcon}
                </Box>

                <Typography
                    variant="h5"
                    textAlign={"center"}
                    color={"white"}
                    fontWeight={"400"}
                    paddingBottom={2}
                >
                    Review from our Happy Clients
                </Typography>
                <Typography variant="h5" color="white" fontWeight={'300'} sx={{
                    padding: '75px 0', lineHeight: 1.75
                }}>
                    {clientReviewData.review}
                </Typography>
                <Typography variant="h6" color="white" gutterBottom>
                    - {clientReviewData.clientName}
                </Typography>
                <Typography variant="p" color="white" sx={{ fontStyle: 'italic' }} gutterBottom>
                    - {clientReviewData.clientDescription}
                </Typography>
                <Box mt={2}>
                    <Rating
                        name="client-rating"
                        value={clientReviewData.starRating}
                        readOnly
                    />
                </Box>
            </Container>
        </Box>
    )
}

export default ClientReview