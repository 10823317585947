import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#3981a3',
      main: '#52b9e9',
      light: '#74c7ed',
    },
    secondary: {
      main: '#52b9e9',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    subtitle1: {
      fontSize: 18,
      lineHeight: 2,
      color: '#757575',
    },
  },
});

export default theme;