import React from "react";
import "./style.scss";
import { Box, Button, Container, Typography } from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <div
      className={`bannerSection ${props.IsHomeBanner ? "homeBanner" : "subBanner"
        }`}
      style={{ backgroundImage: `url(${props.Image})` }}
    >
      {props.IsHomeBanner ? (
        <Container>
          <Box className={'bannerContent'}>
            <Typography
              variant="h2"
              color={"white"}
              fontWeight={"bold"}
              gutterBottom
            >
              {props.BannerHeading}
            </Typography>
            {/* <Button
              variant="contained"
              // startIcon={<VolunteerActivismIcon />}
              size="large"
              component={Link}
              to="https://rzp.io/l/pqlTYtD"
              target="_blank"
            >
              Read More
            </Button> */}
          </Box>
        </Container>
      ) : (
        <Container>
          <Typography variant="h6" textTransform={'uppercase'} color={"#fff"}>
            {props.BannerTitle}
          </Typography>
          <Typography variant="h2" color={"#fff"} fontWeight={'bold'}>
            {props.BannerHeading}
          </Typography>
          <Box sx={{
            maxWidth: '420px', marginTop: '30px'
          }}>
            <Typography variant="p" color={"#fff"}>
              {props.BannerDescription}
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default Banner;
