export const NavItems = [
  {
    path: "/",
    pageName: "Home",
  },
  {
    path: "#",
    pageName: "Consulting & Services",
    children: [
      {
        path: "/hrconsulting",
        pageName: "HR Consulting",
      },
      {
        path: "/technologyconsulting",
        pageName: "Technology Consulting",
      },
      {
        path: "/salesandservices",
        pageName: "Sales & Services",
      },
    ],
  },
  {
    path: "/sucessstories",
    pageName: "Sucess Stories",
  },
  {
    path: "/aboutus",
    pageName: "About Us",
  },
  {
    path: "/contactUs",
    pageName: "Contact Us",
  },
];
