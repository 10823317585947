import React from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./style.scss";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import WhatsAppWidgetIcon from "./../../assets/json/WhatAppWidget";

let SocialMedia = [
  {
    link: "",
    icon: <FacebookOutlinedIcon />,
  },
  {
    link: "",
    icon: <TwitterIcon />,
  },
  {
    link: "",
    icon: <InstagramIcon />,
  },
  {
    link: "",
    icon: <YouTubeIcon />,
  },
];

let FooterLinks = [
  {
    link: "/aboutus",
    pageName: "About Us",
  },
  {
    link: "/hrconsulting",
    pageName: "HR Consulting",
  },
  {
    link: "/sucessstories",
    pageName: "Sucess Stories",
  },
  {
    link: "/contactUs",
    pageName: "Contact Us",
  },
];

const AppFooter = () => {
  return (
    <Box py={8} className="footerSection">
      <Container>
        {/* <Box display={'flex'}>
                    <List>
                        {SocialMedia.map((value, key) => (
                            <ListItem key={key}>
                                <ListItemButton component="a" target='_blank' href={value.link}>
                                    <ListItemIcon>
                                        {value.icon}
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box> */}
        <Box display={"flex"}>
          <List>
            {FooterLinks.map((value, key) => (
              <ListItem key={key}>
                <ListItemButton component={Link} to={value.link}>
                  <ListItemText primary={value.pageName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box textAlign={"center"}>
          Copyright © 2023 Your Consultings - All Rights Reserved.
        </Box>
      </Container>

{/* WhatsApp Widget */}
      <WhatsAppWidgetIcon />

    </Box>
  );
};

export default AppFooter;
