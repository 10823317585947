import React from "react";
import Banner from "../../constants/Banner/Banner";
import BannerImage from "./../../assets/images/About_Us.jpg";
import ImageContent from "../../constants/ImageContent/ImageContent";
import { AboutUs } from "../../assets/json/AboutUs";
import { Box, Container, Typography } from "@mui/material";

const OurTeam = () => {
  return (
    <>
      <Banner
        Image={BannerImage}
        BannerTitle={"Your Consulting"}
        BannerHeading={"About us"}
        BannerDescription={"Navigating your path to growth"}
        IsHomeBanner={false}
      ></Banner>
      <Box className={'sections'} py={10}>
        <Container maxWidth="lg">
          <Typography variant="subtitle1" textAlign={'center'}>
            Established in 2013, Your consulting is a full-service consultant with a focus on sales and services, technology consulting, and HR consulting. To help our customers reach their objectives and maintain an advantage in their respective sectors, our knowledgeable team leverages a wealth of knowledge and experience. We offer specialized solutions to satisfy the particular demands of our clients, from creating effective HR policies to integrating cutting-edge technology. Through our excellent consulting services, our primary goal is to assist organizations in increasing their effectiveness, productivity, and profitability.
          </Typography>
        </Container>
      </Box>
      <Box py={10}>
        {AboutUs[0].ourMission.map((value, key) => (
          <ImageContent
            key={key}
            Image={value.Image}
            Heading={value.Heading}
            Paragraph={value.Content}
            reverse={true}
          />
        ))}
        {AboutUs[0].ourVision.map((value, key) => (
          <ImageContent
            key={key}
            Image={value.Image}
            Heading={value.Heading}
            Paragraph={value.Content}
          />
        ))}

        {AboutUs[0].ourValues.map((value, key) => (
          <ImageContent
            key={key}
            Image={value.Image}
            Heading={value.Heading}
            Paragraph={value.Content}
            reverse={true}
          />
        ))}
      </Box>
    </>
  );
};

export default OurTeam;
