import { Typography } from '@mui/material'
import React from 'react'

const Heading = (props) => {
    const { Title, TitleHeading, IsTextCenter } = props
    return (
        <>
            {IsTextCenter ?
                <Typography variant="subtitle1" marginBottom={1} color="primary" textTransform={'uppercase'} textAlign={'center'}>
                    {Title}
                </Typography>
                : <Typography variant="h6" marginBottom={2.5} color="primary" textTransform={'uppercase'}>
                    {Title}
                </Typography>
            }
            <Typography variant="h4" marginBottom={2.5} fontWeight={'bold'} textAlign={IsTextCenter ? 'center' : ''}>
                {TitleHeading}
            </Typography>
        </>
    )
}

export default Heading