import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import './HelpYou.scss'
import { Link } from 'react-router-dom'

const HelpYou = (props) => {
    const {Image, Heading, Content, ButtonText, path} = props
    return (
        <Box className={'helpYouContainer'}>
            <Box display={'flex'}>
                <img
                    src={Image}
                    alt="Aboutimage"
                    className={'helpYouImage'}
                />
            </Box>
            <Box p={12}>
                <Typography variant="h3" fontWeight={'bold'} gutterBottom color={'#fff'}>
                    {Heading}
                </Typography>
                <Typography variant="h5" gutterBottom paddingBottom={4} color={'#fff'}>
                    {Content}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "fit-content" }}
                    component={Link}
                    to={path ? path : '/'}
                >
                    {ButtonText}
                </Button>
            </Box>
        </Box>
    )
}

export default HelpYou